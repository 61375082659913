import { colors, spacing } from '../../styles/jss/variables';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: colors.supplementary.white,
        paddingTop: spacing.medium,
        paddingLeft: spacing.small,
        paddingRight: spacing.small,
        [theme.breakpoints.up('md')]: {
            paddingTop: spacing.large,
            paddingLeft: spacing.large,
            paddingRight: spacing.large,
        },
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    textContent: {
        alignContent: 'center',
    },
    heading: Object.assign(Object.assign({}, theme.typography.xl_bold), { paddingTop: 0, paddingBottom: 0, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl_bold), { paddingTop: 0, paddingBottom: 0 }) }),
    subHeading: Object.assign(Object.assign({}, theme.typography.small_regular), { marginTop: spacing.medium, [theme.breakpoints.up('md')]: {
            marginTop: spacing.large,
        } }),
    image: {
        width: '100%',
        maxWidth: 638,
        minWidth: 335,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            marginTop: spacing.small,
            alignSelf: 'center',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    homePageLinkText: Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.primary.gold5, textDecoration: 'underline', display: 'block', marginTop: spacing.small, [theme.breakpoints.up('md')]: {
            marginTop: spacing.large,
        }, cursor: 'pointer' }),
    componentContainer: {
        paddingBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            paddingBottom: spacing.xl,
        },
    },
}));
export default useStyles;
