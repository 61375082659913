import { getOutlineRenderGraph, OutlineRenderGraph } from 'utils/getRenderGraph'
import Page from '../components/Page/Page'
import { renderComponents } from '../utils/components'
import PageNotFound from '@interflora/ui-components/build/components/PageNotFound/PageNotFound'
import {
  PRODUCT_GALLERY_UK_AMP_KEY,
  PRODUCT_GALLERY_IE_AMP_KEY,
} from '@interflora/ui-components/build/components/ProductGallery/ProductGallery'

const Index = (renderGraph: OutlineRenderGraph) => {
  const { page } = renderGraph
  return (
    <Page renderGraph={renderGraph}>
      {renderComponents(page.components.header)}
      <PageNotFound />
      {renderComponents(page.components.footer)}
    </Page>
  )
}

export default Index

export const getStaticProps = (ctx) =>
  getOutlineRenderGraph(ctx, 'DEFAULT', '404', { title: 'Page Not Found', noIndex: true, noFollow: false }, false, [
    PRODUCT_GALLERY_UK_AMP_KEY,
    PRODUCT_GALLERY_IE_AMP_KEY,
  ])
