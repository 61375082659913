import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MainContent from '../MainContent/MainContent';
import BreadcrumbBar from '../BreadcrumbBar/BreadcrumbBar';
import Link from '../Link/Link';
import LinkGallery from '../LinkGallery/LinkGallery';
import useStyles from './PageNotFound.styles';
import { useContext, useEffect } from 'react';
import { SiteContext } from '../../utils/common';
import RawHtml from '../RawHtml/RawHtml';
import PreloadedComponent from '../PreloadedComponent/PreloadedComponent';
import ProductGallery, { PRODUCT_GALLERY_UK_AMP_KEY, PRODUCT_GALLERY_IE_AMP_KEY, } from '../ProductGallery/ProductGallery';
import clsx from 'clsx';
const linkGalleryProps = {
    description: 'What about one of these instead? ',
    links: [
        {
            label: 'Birthday flowers',
            url: '/category/birthday-flowers',
        },
        {
            label: 'Anniversary flowers',
            url: '/category/anniversary-flowers',
        },
        {
            label: 'Thank you flowers',
            url: '/category/occasion-flowers/thank-you',
        },
        { label: 'Need some help?', url: '/page/help' },
        { label: 'Visit our blog', url: '/blog' },
    ],
};
const homePageLinkProps = {
    label: 'Return to the homepage',
    url: '/',
    newWindow: false,
};
const PageNotFound = () => {
    const { classes } = useStyles();
    const { isFlyingFlowers, shorthand } = useContext(SiteContext);
    const productGalleryAmpKey = shorthand === 'uk' ? PRODUCT_GALLERY_UK_AMP_KEY : PRODUCT_GALLERY_IE_AMP_KEY;
    useEffect(() => {
        sessionStorage.removeItem('breadcrumb');
    }, []);
    return (_jsx(_Fragment, { children: isFlyingFlowers ? (_jsx(MainContent, { children: _jsx(RawHtml, { content: '<h1>Page Not Found</h1>' }) })) : (_jsxs(_Fragment, { children: [_jsx(BreadcrumbBar, { links: [
                        { label: 'Home', url: '/' },
                        { label: 'Page Not Found', url: '/' },
                    ] }), _jsxs("div", { className: classes.root, children: [_jsxs("div", { className: clsx(classes.contentContainer, classes.componentContainer), children: [_jsxs("div", { className: classes.textContent, children: [_jsxs("div", { className: classes.heading, children: [_jsx("span", { children: "Hmm," }), _jsx("br", {}), _jsx("span", { children: "there\u2019s no flowers here" })] }), _jsx("div", { className: classes.subHeading, children: "Sorry about that." }), _jsx(Link, Object.assign({}, homePageLinkProps, { className: classes.homePageLinkText }))] }), _jsx("img", { className: classes.image, alt: "error-page-img", src: "/images/error-page-image.png" })] }), _jsx("div", { className: classes.componentContainer, children: _jsx(LinkGallery, Object.assign({}, linkGalleryProps)) }), _jsx("div", { className: classes.componentContainer, children: _jsx(PreloadedComponent, { Component: ProductGallery, componentKey: productGalleryAmpKey }) })] })] })) }));
};
export default PageNotFound;
